import { lazy } from "react";

import MainLayout from "layout/MainLayout";
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import Copy from "pages/copy";

const Dashboard = Loadable(lazy(() => import("pages/dashboard")));
const Orders = Loadable(lazy(() => import("pages/orders")));
const Sales = Loadable(lazy(() => import("pages/sales")));
const FreshLeads = Loadable(lazy(() => import("pages/freshLeads")));
const OldLeads = Loadable(lazy(() => import("pages/oldLeads")));
const Profile = Loadable(lazy(() => import("pages/profile")));

const MainRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "",
      element: <Dashboard />,
    },
    {
      path: "orders",
      element: <Orders />,
    },
    {
      path: "sales",
      element: <Sales />,
    },
    {
      path: "freshLeads",
      element: <FreshLeads />,
    },
    {
      path: "oldLeads",
      element: <OldLeads />,
    },
    {
      path: "copy",
      element: <Copy />,
    },
    {
      path: "profile",
      element: <Profile />,
    },
  ],
};

export default MainRoutes;
