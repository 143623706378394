// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  BorderOutlined,
  BoxPlotOutlined,
  ChromeOutlined,
  DashboardOutlined,
  DeploymentUnitOutlined,
  GatewayOutlined,
  MenuUnfoldOutlined,
  QuestionOutlined,
  HeartOutlined,
  RocketOutlined,
  FireOutlined,
  TagOutlined,
  GithubOutlined,
  InstagramOutlined,
  WhatsAppOutlined,
  GlobalOutlined,
} from "@ant-design/icons";

// icons
const icons = {
  ChromeOutlined,
  MenuUnfoldOutlined,
  BoxPlotOutlined,
  RocketOutlined,
  FireOutlined,
  BorderOutlined,
  HeartOutlined,
  GatewayOutlined,
  QuestionOutlined,
  DeploymentUnitOutlined,
  DashboardOutlined,
  TagOutlined,
  GithubOutlined,
  InstagramOutlined,
  WhatsAppOutlined,
  GlobalOutlined,
};

const other = {
  id: "links",
  title: <FormattedMessage id="Links" />,
  type: "group",
  children: [
    {
      id: "firebase-console",
      title: <FormattedMessage id="Firebase Console" />,
      type: "item",
      url: "https://console.firebase.google.com/u/0/project/development-db-e60de/overview",
      icon: icons.ChromeOutlined,
      target: true,
    },
    {
      id: "homepage",
      title: <FormattedMessage id="Nords Oy" />,
      type: "item",
      url: "https://www.nords.fi/",
      icon: icons.GlobalOutlined,
      target: true,
    },
  ],
};

export default other;
