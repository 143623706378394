// third-party
import { createSlice } from "@reduxjs/toolkit";
import { firestore } from "contexts/FirebaseContext";
import {
  collection,
  deleteDoc,
  doc,
  getCountFromServer,
  limit,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { dispatch } from "store";

const initialState = {
  isOpen: false,
  isCustomerOpen: false,
  open: false,
  oldLeadList: [],
  list: null,
  error: null,
  alertPopup: false,
};

const oldLead = createSlice({
  name: "oldLead",
  initialState,
  reducers: {
    // review oldLead popup
    reviewOldLeadPopup(state, action) {
      state.isOpen = action.payload.isOpen;
    },

    // is customer open
    customerPopup(state, action) {
      state.isCustomerOpen = action.payload.isCustomerOpen;
    },

    // handler customer form popup
    toggleCustomerPopup(state, action) {
      state.open = action.payload.open;
    },

    // handler customer form popup
    selectCountry(state, action) {
      state.country = action.payload.country;
    },

    hasError(state, action) {
      state.error = action.payload.error;
    },

    // get all oldLead list
    getLists(state, action) {
      state.oldLeadList = action.payload;
    },

    // get oldLead details
    getSingleList(state, action) {
      state.list = action.payload;
    },

    // create oldLead
    createOldLead(state, action) {
      state.oldLeadList = [...state.oldLeadList, action.payload];
    },

    // update oldLead
    UpdateOldLead(state, action) {
      const { NewOldLead } = action.payload;
      const OldLeadUpdate = state.oldLeadList.map((item) => {
        if (item.id === NewOldLead.id) {
          return NewOldLead;
        }
        return item;
      });
      state.oldLeadList = OldLeadUpdate;
    },

    // delete oldLead
    deleteOldLead(state, action) {
      const { invoiceId } = action.payload;
      const deleteOldLead = state.oldLeadList.filter((list) => list.id !== invoiceId);
      state.oldLeadList = deleteOldLead;
    },

    //alert popup
    alertPopupToggle(state, action) {
      state.alertPopup = action.payload.alertToggle;
    },
  },
});

export default oldLead.reducer;

export const {
  reviewLeadPopup,
  customerPopup,
  toggleCustomerPopup,
  selectCountry,
  getLists,
  alertPopupToggle,
} = oldLead.actions;

export const subscribeToOldLeads = (stageFilter) => {
  const q = query(
    collection(firestore, "leads/data/old_leads"),
    where("stage", "==", stageFilter),
    orderBy("last_contacted"),
    orderBy("businessId", "desc"),
    limit(20)
  );
  const unsubscribeFunction = onSnapshot(q, (snapshot) => {
    const data = snapshot.docs.map((doc) => doc.data());
    dispatch(oldLead.actions.getLists(data));
  });

  return unsubscribeFunction;
};

export async function getOldLeadsCount() {
  const col = collection(firestore, "leads/data/old_leads");
  const snapshot = await getCountFromServer(col);
  return snapshot.data().count;
}

export function getOldLeadInsert(NewLists) {
  throw new Error("Error: getOldLeadInsert not implemented");
}

export async function updateOldLead(oldLead) {
  try {
    const docRef = doc(collection(firestore, "leads/data/old_leads"), oldLead.id);
    await updateDoc(docRef, oldLead);
  } catch (error) {
    console.error(error);
    window.alert(`An error occurred while updating fresh lead: ${error.message}`);
  }
}

export function deleteOldLead(id) {
  deleteDoc(doc(firestore, "leads/data/old_leads", id));
}

export function getOldLeadSingleList(invoiceId) {
  throw new Error("Error: getOldLeadSingleList not implemented");
}
