// material-ui
import { Grid, Typography } from "@mui/material";

// assets
import TabCopy from "./TabCopy";
import { useSelector } from "react-redux";

/**
 * Renders the Copy component displaying copywriting.
 * @returns {JSX.Element} - JSX element representing the Copy component.
 */
const Copy = () => {
  const { copys } = useSelector((state) => state.copy);

  return (
    <>
      <Grid item xs={12} sx={{ mb: 3 }}>
        <Typography variant="h3">Copy</Typography>
      </Grid>
      <Grid container xs={12} rowSpacing={4.5} columnSpacing={3}>
        {copys.map((copy) => (
          <Grid item xs={12} lg={6}>
            <TabCopy copy={copy} name={copy.name} subject={copy.subject} body={copy.body} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Copy;
