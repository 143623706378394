import { useOutletContext } from "react-router";
import { Box, Button, Grid, InputLabel, Stack, TextField } from "@mui/material";
import { Formik } from "formik";
import MainCard from "components/MainCard";
import { dispatch } from "store";
import { openSnackbar } from "store/reducers/snackbar";
import { updateCopy } from "store/reducers/copy";
import { sendWhatsapp } from "utils/whatsapp";

function useInputRef() {
  return useOutletContext();
}

const TabCopy = ({ copy }) => {
  const inputRef = useInputRef();

  // Ensure copy is defined before rendering
  if (!copy) {
    return <div>Loading...</div>; // Or any other loading indicator
  }

  const sendTestMessage = (body) => {
    const currentDayNumeric = new Date().getDay();
    const currentDay =
      currentDayNumeric === 1
        ? "maanantai"
        : currentDayNumeric === 2
        ? "tiistai"
        : currentDayNumeric === 3
        ? "keskiviikko"
        : currentDayNumeric === 4
        ? "torstai"
        : currentDayNumeric === 5
        ? "perjantai"
        : currentDayNumeric === 6
        ? "lauantai"
        : "sunnuntai";
    const clean = (str) =>
      str
        .replaceAll("{{current_day}}", currentDay)
        .replaceAll("{{company_name}}", "Nords Oy")
        .replaceAll("{{new_line}}", "\n");

    const cleanBody = clean(body);
    sendWhatsapp("358402469419", cleanBody);
  };

  const handleSave = (values) => {
    // Merge the current copy with updated values
    const updatedCopy = { ...copy, body: values.body };

    // Call updateCopy with the updated data
    updateCopy(updatedCopy).then(() => {
      dispatch(
        openSnackbar({
          open: true,
          message: "Copy for " + copy.name + " saved successfully.",
          variant: "alert",
          alert: {
            color: "success",
          },
          close: true,
        })
      );
    });
  };

  return (
    <MainCard
      content={false}
      title={copy?.name || "Untitled Copy"} // Added fallback for copy?.name
      sx={{ "& .MuiInputLabel-root": { fontSize: "0.875rem" } }}
    >
      <Formik
        initialValues={{
          body: copy?.body || "",
        }}
        onSubmit={(values, { setErrors, setStatus, setSubmitting }) => {
          try {
            dispatch(
              openSnackbar({
                open: true,
                message: "Personal profile updated successfully.",
                variant: "alert",
                alert: {
                  color: "success",
                },
                close: false,
              })
            );
            setStatus({ success: true });
            setSubmitting(false);
          } catch (err) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }}
      >
        {({ values, handleBlur, handleChange, handleSubmit }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Box sx={{ p: 2.5 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <Stack spacing={1.25}>
                    <InputLabel htmlFor="body">Body</InputLabel>
                    <TextField
                      type="text"
                      multiline
                      fullWidth
                      value={values.body}
                      name="body"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      id="body"
                      placeholder="Email Body"
                    />
                  </Stack>
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    sx={{ mt: 3, mr: 2 }}
                    onClick={() => sendTestMessage(values.body)}
                  >
                    Send Test Message
                  </Button>
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    sx={{ mt: 3 }}
                    onClick={() => handleSave(values)}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        )}
      </Formik>
    </MainCard>
  );
};

export default TabCopy;
