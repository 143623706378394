import { createSlice } from "@reduxjs/toolkit";
import { collection, doc, onSnapshot, setDoc } from "firebase/firestore";
import { firestore } from "contexts/FirebaseContext";
import { dispatch } from "store";

/**
 * Initial state for the copy slice.
 */
const initialState = {
  copys: [],
  copy: null,
  error: null,
};

/**
 * Redux slice for managing copy.
 * @type {Slice}
 */
const copy = createSlice({
  name: "copy",
  initialState,
  reducers: {
    getAll(state, action) {
      state.copys = action.payload;
    },

    get(state, action) {
      state.copy = action.payload;
    },

    create(state, action) {
      state.copy = [...state.copy, action.payload];
    },

    update(state, action) {
      const { newCopy } = action.payload;
      const copyUpdate = state.copy.map((item) => {
        if (item.id === newCopy.id) {
          return newCopy;
        }
        return item;
      });
      state.copy = copyUpdate;
    },

    delete(state, action) {
      const { copyId } = action.payload;
      const deleteCopy = state.copy.filter((copy) => copy.id !== copyId);
      state.copy = deleteCopy;
    },
  },
});

export default copy.reducer;

export const { getAll } = copy.actions;
/**
 * Function to subscribe to copy updates from Firestore.
 * @function
 * @returns {Function} Unsubscribe function.
 */
export const subscribeToCopys = () => {
  const unsubscribe = onSnapshot(collection(firestore, "copy"), (snapshot) => {
    const data = snapshot.docs.map((doc) => doc.data());
    dispatch(copy.actions.getAll(data));
  });

  return unsubscribe;
};

/**
 * Function to create a new copy in Firestore.
 * @async
 * @param {object} copy - The copy object to be created.
 */
export async function createCopy(copy) {
  try {
    const docRef = doc(collection(firestore, "copy"), copy.id);
    await setDoc(docRef, copy);
  } catch (error) {
    console.error(error);
  }
}

/**
 * Function to update an existing copy in Firestore.
 * @async
 * @param {object} copy - The copy object to be updated.
 */
export async function updateCopy(copy) {
  try {
    const docRef = doc(collection(firestore, "copy"), copy.id);
    await setDoc(docRef, copy);
  } catch (error) {
    console.error(error);
  }
}
