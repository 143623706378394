// third-party
import { combineReducers } from "redux";

// project import
import menu from "./menu";
import snackbar from "./snackbar";
import order from "./order";
import sale from "./sale";
import freshLead from "./freshLead";
import oldLead from "./oldLead";
import data from "./data";
import user from "./user";
import copy from "./copy";

const reducers = combineReducers({
  menu,
  snackbar,
  order,
  sale,
  freshLead,
  oldLead,
  data,
  user,
  copy,
});

export default reducers;
